export default {
  name : "BulkImportCases",
  emits: ["cancel"],
  data : () => ({
    currentImportCasesStep: 1
  }),
  methods: {
    cancelImportCases() {
      this.showBulkImportCasesDialog = false
      this.currentImportCasesStep    = 1
      this.$emit("cancel")
    }
  },
  computed: {
    stepsForImportCases() {
      return [{
        stepNumber: 1,
        label     : this.$t("1870")
      }, {
        stepNumber: 2,
        label     : this.$t("1871")
      }, {
        stepNumber: 3,
        label     : this.$t("1872")
      }]
    },
    isNextStepDisabled() {
      return true
    },
    isStartImportDisabled() {
      return true
    }
  }
}